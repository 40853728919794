import { httpService } from "../../services/http.service";
import { Service } from "../models/service.model";

const state = {
  services: null
};

const actions = {
  async getAllServices({ commit }) {
    const response = await httpService.get("services");
    const services = [];
    response.data.data.forEach(service => {
      services.push(new Service(services));
    });
    commit("setServices", services);
  },
  async createService({ commit, dispatch }, data) {
    const response = await httpService.post("services", data);
    dispatch("getAllServices");
    return response;
  },
  async updateService({ commit, dispatch }, data) {
    const response = await httpService.put(`services/${data.id}`, {
      title: data.title
    });
    dispatch("getAllServices");
    return response;
  },
  async updateServicePdf({ commit, dispatch }, data) {
    const response = await httpService.post(
      `services/${data.get("id")}/update-pdf`,
      data
    );
    dispatch("getAllServices");
    return response;
  },
  async deleteService({ commit, dispatch }, data) {
    const response = await httpService.delete(`services/${data.id}`);
    dispatch("getAllServices");
    return response;
  }
};

const mutations = {
  setServices(state, services) {
    state.services = services;
  }
};

export default { state, mutations, actions };
