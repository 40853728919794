import { AuthService } from "../../services/auth.service";
import { httpService } from "../../services/http.service";

const authService = new AuthService();

const state = {
  user: authService.getUser() || null
};

const actions = {
  async login({ commit }, userLogin) {
    const response = await httpService.post("login", userLogin);
    authService.setToken((await response).data.data.token);
    authService.setUser((await response).data.data.user);
    commit("setUser", response);
    return response;
  },
  async logout({ commit }) {
    await httpService.post("logout");
    commit("logout");
  },
  async register(context, userRegister) {
    await httpService.post("register", userRegister);
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  logout(state) {
    state.user = null;
    authService.logout();
  }
};

export default { state, mutations, actions };
