<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center page-loader"
  >
    <div class="loading-modal d-flex flex-column align-items-center justify-content-center">
      <div class="loading">
        <img src="../assets/nasida-loader.svg" />
      </div>
      <p class="loading-text">LOADING</p>
    </div>
    <div class="bg-overlay"></div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.page-loader {
  height: 100vh;
  padding: 78px 30px 30px 100px;
  position: relative;
}

.bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.3;
  z-index: 100;
}

.loading {
  width: 90px;
  height: 90px;
}

.loading-modal {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  z-index: 300;
  background-color: white;
}

.loading-text {
  font-weight: 600;
  color: white;
}

.loading img {
  object-fit: contain;
}
</style>
