<template>
  <div class="white-content">
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    }
  },
  mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
  }
};
</script>

<style>
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: lightgray;
}

*::-webkit-scrollbar-thumb {
  background-color: #389466;
}

.full-height {
  height: 85vh;
  overflow-y: auto;
}

.white-content {
  overflow: hidden;
  height: 100vh;
}

.uploadable {
  position: relative;
}

.uploadable .upload {
  position: absolute;
  background-color: #000;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.uploadable .upload p {
  color: #fff;
  cursor: pointer;
}

.uploadable:hover .upload {
  opacity: 0.4;
}

</style>
