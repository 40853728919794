import { httpService } from "../../services/http.service";
import { Bio } from "../models/bio.model";

const state = {
  nieacMembers: null
};

const actions = {
  async getNIEACMembers({ commit }) {
    const response = await httpService.get("members");
    const nieacMembers = [];
    response.data.data.forEach(member => {
      nieacMembers.push(new Bio(member));
    });
    commit("setNIEACMembers", nieacMembers);
  },
  async createNIEACMember({ commit, dispatch }, data) {
    const response = await httpService.post("members", data);
    dispatch("getNIEACMembers");
    return response;
  },
  async updateNIEACMember({ commit, dispatch }, data) {
    const response = await httpService.put(`members/${data.id}`, {
      name: data.name,
      bio: data.profile
    });
    dispatch("getNIEACMembers");
    return response;
  },
  async updateNIEACMemberPhoto({ commit, dispatch }, data) {
    const response = await httpService.post(
      `members/${data.get("id")}/update-photo`,
      data
    );
    dispatch("getNIEACMembers");
    return response;
  },
  async deleteNIEACMember({ commit, dispatch }, data) {
    const response = await httpService.delete(`members/${data.id}`);
    dispatch("getNIEACMembers");
    return response;
  }
};

const mutations = {
  setNIEACMembers(state, nieacMembers) {
    state.nieacMembers = nieacMembers;
  }
};

export default { state, mutations, actions };
