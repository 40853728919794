export class AuthService {
  setToken(token) {
    localStorage.setItem("nasida-token", JSON.stringify(token));
  }

  getToken() {
    return JSON.parse(localStorage.getItem("nasida-token"));
  }

  setUser(user) {
    localStorage.setItem("nasida-user", JSON.stringify(user));
  }

  getUser() {
    const user = localStorage.getItem("nasida-user");
    return JSON.parse(user);
  }

  logout() {
    localStorage.clear();
  }
}

export const isAuthenticated = () => {
  if (AuthService.prototype.getToken()) {
    return true;
  } else {
    return false;
  }
};
