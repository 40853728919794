import { isAuthenticated } from "../services/auth.service";

export const authGuard = (to, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuthenticated()) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
};

export const metaWrapper = (to, from) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.name);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.name || nearestWithTitle.name} | NASIDA Admin`;
  } else if (previousNearestWithMeta) {
    document.title =
      previousNearestWithMeta.meta.name || previousNearestWithMeta.name;
  }

  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode?.removeChild(el));

  // appendTags(nearestWithMeta.meta.metaTags);
};

export function appendTags(tags) {
  tags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));
}
