import Vue from "vue";
import Vuex from "vuex";

import authModule from "./modules/auth.module";
import loadingModule from "./modules/loading.module";
import governorsModule from "./modules/governors.module";
import corporationsModule from "./modules/corporations.module";
import nieacMembersModule from "./modules/nieac-members.module";
import pressReleasesModule from "./modules/press-releases.module";
import managementTeamModule from "./modules/management-team.module";
import investorSupportServicesModule from "./modules/investor-support-services.module";
import servicesModule from "./modules/services.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authModule,
    loadingModule,
    governorsModule,
    nieacMembersModule,
    corporationsModule,
    pressReleasesModule,
    managementTeamModule,
    investorSupportServicesModule,
    servicesModule
  }
});
