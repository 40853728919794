import { httpService } from "../../services/http.service";
import { TeamMember } from "../models/bio.model";

const state = {
  managementTeam: null,
  mdCEO: null
};

const actions = {
  async getManagementTeam({ commit }) {
    const response = await httpService.get("teams");
    const managementTeam = [];
    response.data.data.forEach(member => {
      if (member.position !== "MD/CEO") {
        managementTeam.push(new TeamMember(member));
      }
    });
    commit("setManagementTeam", managementTeam);
  },
  async getMDCEO({ commit }) {
    const response = await httpService.get("teams");
    const mdCEO = new TeamMember(
      response.data.data.find(member => member.position == "MD/CEO")
    );
    commit("setMDCEO", mdCEO);
  },
  async createTeamMember({ commit, dispatch }, data) {
    const response = await httpService.post("teams", data);
    dispatch("getManagementTeam");
    return response;
  },
  async updateTeamMember({ commit, dispatch }, data) {
    const response = await httpService.put(`teams/${data.id}`, {
      name: data.name,
      bio: data.profile
    });
    dispatch("getManagementTeam");
    return response;
  },
  async updateTeamMemberPhoto({ commit, dispatch }, data) {
    const response = await httpService.post(
      `teams/${data.get("id")}/update-photo`,
      data
    );
    dispatch("getManagementTeam");
    return response;
  },
  async deleteTeamMember({ commit, dispatch }, data) {
    const response = await httpService.delete(`teams/${data.id}`);
    dispatch("getManagementTeam");
    return response;
  }
};

const mutations = {
  setManagementTeam(state, managementTeam) {
    state.managementTeam = managementTeam;
  },
  setMDCEO(state, mdCEO) {
    state.mdCEO = mdCEO;
  }
};

export default { state, mutations, actions };
