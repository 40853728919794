import { httpService } from "../../services/http.service";
import { PressRelease } from "../models/press-release.model";

const state = {
  pressReleases: []
};

const actions = {
  async getAllPressReleases({ commit }, page) {
    const response = await httpService.get("press-releases?page=${page || 1}"); 
    const pressReleases = [];
    response.data.data.data.forEach(pressRelease => {
      const _currentPressRelease = new PressRelease(pressRelease);
      let duplicatePressRelease = false;
      state.pressReleases.map(pressRelease => {
        if (pressRelease.id == _currentPressRelease.id) {
          duplicatePressRelease = true;
        }
      });
      if (!duplicatePressRelease) {
        pressReleases.push(_currentPressRelease);
      }
    });
    if (state.pressReleases.length <= response.data.data.per_page * page) {
      commit("setPressReleases", pressReleases.reverse());
    }
    return response.data.data.next_page_url;
  },
  async getPressRelease(context, id) {
    const response = await httpService.get(`press-releases/${id}`);
    const pressRelease = new PressRelease(response.data.data);
    return pressRelease;
  },
  async createPressRelease({ commit, dispatch }, data) {
    const response = await httpService.post("press-releases", data);
    dispatch("getAllPressReleases");
    return response;
  },
  async updatePressRelease({ commit, dispatch }, data) {
    const response = await httpService.put(`press-releases/${data.id}`, {
      title: data.title,
      content: data.content
    });
    dispatch("getAllPressReleases");
    return response;
  },
  async updatePressReleasePhoto({ commit, dispatch }, data) {
    const response = await httpService.post(
      `press-releases/${data.get("id")}/update-image`,
      data
    );
    dispatch("getAllPressReleases");
    return response;
  },
  async deletePressRelease({ commit, dispatch }, data) {
    const response = await httpService.delete(`press-releases/${data.id}`);
    dispatch("getAllPressReleases");
    return response;
  }
};

const mutations = {
  setPressReleases(state, pressReleases) {
    state.pressReleases.push(...pressReleases);
  }
};

export default { state, mutations, actions };
