export class PressRelease {
  constructor(pressRelease) {
    this.id = pressRelease.id;
    this.title = pressRelease.title;
    this.image = pressRelease.image;
    this.content = pressRelease.content;
    this.created_at = new Date(
      pressRelease.created_at
    ).toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  }
}
