import VueRouter from "vue-router";
import { authGuard, metaWrapper } from "./guards";
import routes from "./routes";

// configure router
const router = new VueRouter({
  routes,
  mode: "history",
  linkExactActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  authGuard(to, next);
  metaWrapper(to, from);
});

export default router;
