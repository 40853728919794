import { httpService } from "../../services/http.service";
import { Bio } from "../models/bio.model";

const state = {
  governor: null
};

const actions = {
  async getGovernor({ commit }) {
    const response = await httpService.get("governors");
    const governor = new Bio(response.data.data[0]);
    commit("setGovernor", governor);
  },
  async updateGovernor({ commit, dispatch }, data) {
    const response = await httpService.put(`governors/${data.id}`, {
      name: data.name,
      bio: data.profile
    });
    dispatch("getGovernor");
    return response;
  },
  async updateGovernorPhoto({ commit, dispatch }, data) {
    const response = await httpService.post(
      `governors/${data.get("id")}/update-photo`,
      data
    );
    dispatch("getGovernor");
    return response;
  }
};

const mutations = {
  setGovernor(state, governor) {
    state.governor = governor;
  }
};

export default { state, mutations, actions };
