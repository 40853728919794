import { httpService } from "../../services/http.service";
import { Corporation } from "../models/corporation.model";

const state = {
  corporations: null
};

const actions = {
  async getAllCorporations({ commit }) {
    const response = await httpService.get("corporations");
    const corporations = [];
    response.data.data.forEach(corporation => {
      corporations.push(new Corporation(corporation));
    });
    commit("setCorporations", corporations);
  },
  async createCorporation({ commit, dispatch }, data) {
    const response = await httpService.post("corporations", data);
    dispatch("getAllCorporations");
    return response;
  },
  async updateCorporation({ commit, dispatch }, data) {
    const response = await httpService.put(`corporations/${data.id}`, {
      website_url: data.website_url
    });
    dispatch("getAllCorporations");
    return response;
  },
  async updateCorporationLogo({ commit, dispatch }, data) {
    const response = await httpService.post(
      `corporations/${data.get("id")}/update-photo`,
      data
    );
    dispatch("getAllCorporations");
    return response;
  },
  async deleteCorporation({ commit, dispatch }, data) {
    const response = await httpService.delete(`corporations/${data.id}`);
    dispatch("getAllCorporations");
    return response;
  }
};

const mutations = {
  setCorporations(state, corporations) {
    state.corporations = corporations;
  }
};

export default { state, mutations, actions };
