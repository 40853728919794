<template>
  <div>
    <div class="wrapper d-none d-lg-block">
      <side-bar>
        <template slot="links">
          <sidebar-link
            to="/press-releases"
            :name="'Press Releases'"
            icon="tim-icons icon-book-bookmark"
          />
          <sidebar-link
            to="/publications"
            :name="'Publications'"
            icon="tim-icons icon-align-center"
          />
          <sidebar-link
            to="/leadership"
            :name="'Leadership'"
            icon="tim-icons icon-bulb-63"
          />
          <sidebar-link
            to="/management-team"
            :name="'Management Team'"
            icon="tim-icons icon-single-02"
          />
          <sidebar-link
            to="/advisory-council"
            :name="'Advisory Council'"
            icon="tim-icons icon-molecule-40"
          />
          <sidebar-link
            to="/corporations"
            :name="'Corporations'"
            icon="tim-icons icon-bank"
          />
        </template>
      </side-bar>
      <div class="main-panel" data="green">
        <top-navbar></top-navbar>

        <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center mobile-404 d-lg-none"
    >
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="nasida-404-logo">
          <img src="../../assets/logo.svg" alt="" />
        </div>
        <p class="text-center font-weight-bold mt-4">
          The admin dashboard is not accessible <br />
          on mobile devices. Please login from a PC.
        </p>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    DashboardContent,
    MobileMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style scoped>
.mobile-404 {
  height: 98vh;
}

.nasida-404-logo {
  width: 100px;
}
</style>
