<template>
  <div class="content">
    <page-loader class="loader-global" v-if="loading" />
    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->
      <router-view class="lower-index"></router-view>
    </fade-transition>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import store from "../../store/index";
import PageLoader from "../../components/Loading.vue";

export default {
  components: {
    FadeTransition,
    PageLoader,
  },
  computed: {
    loading() {
      return store.state.loadingModule.loading;
    },
  },
};
</script>
<style scoped>
.content {
  position: relative;
}
.loader-global {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
}

.lower-index {
  z-index: 150;
}
</style>
