import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import AuthenticationLayout from "@/layout/authentication/AuthenticationLayout.vue";

const load = component => {
  return () => import(`@/pages/${component}.vue`);
};

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/press-releases",
    meta: { requiresAuth: true },
    children: [
      {
        path: "press-releases",
        name: "Press Releases",
        component: load("PressReleases")
      },
      {
        path: "leadership",
        name: "Leadership",
        component: load("Leadership")
      },
      {
        path: "corporations",
        name: "Corporations",
        component: load("Corporations")
      },
      {
        path: "publications",
        name: "Publications",
        component: load("Publications")
      },
      {
        path: "management-team",
        name: "Management Team",
        component: load("ManagementTeam")
      },
      {
        path: "advisory-council",
        name: "Economic Advisory Council",
        component: load("AdvisoryCouncil")
      },
      // {
      //   path: "services",
      //   name: "services",
      //   component: load("Services")
      // },
    ]
  },
  {
    path: "/",
    component: AuthenticationLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: "login",
        name: "Login",
        component: load("Login")
      }
    ]
  },
  { path: "*", component: load("NotFoundPage") }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
