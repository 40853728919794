import { httpService } from "../../services/http.service";
import { SupportService } from "../models/support-service.model";

const state = {
  investorSupportServices: null
};

const actions = {
  async getAllSupportServices({ commit }) {
    const response = await httpService.get(`investor-support-services`);
    const investorSupportServices = [];
    response.data.data.forEach(supportService => {
      investorSupportServices.push(new SupportService(supportService));
    });
    commit("setSupportServices", investorSupportServices); 
  },
  async createSupportService({ commit, dispatch }, data) {
    const response = await httpService.post("investor-support-services", data);
    dispatch("getAllSupportServices");
    return response;
  },
  async updateSupportService({ commit, dispatch }, data) {
    const response = await httpService.put(
      `investor-support-services/${data.id}`,
      {
        name: data.name,
        link: data.link
      }
    );
    dispatch("getAllSupportServices");
    return response;
  },
  async deleteSupportService({ commit, dispatch }, data) {
    const response = await httpService.delete(
      `investor-support-services/${data.id}`
    );
    dispatch("getAllSupportServices");
    return response;
  }
};

const mutations = {
  setSupportServices(state, investorSupportServices) {
    state.investorSupportServices = investorSupportServices;
  }
};

export default { state, mutations, actions };
